import { NavigationItem } from '@audi/audi-ui-react';
import React from 'react';
import { DesktopNavItemProps } from '../../ContentType';

export const DesktopNavigationItem: React.FunctionComponent<DesktopNavItemProps> = ({
  children,
  ...props
}) => {
  return <NavigationItem {...props}>{children}</NavigationItem>;
};
