import React, { useMemo } from 'react';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import type { Logger } from '@feature-hub/logger';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';

export interface FeatureAppContextState {
  readonly logger: Logger;
  readonly trackingService: TrackingServiceV2 | undefined;
  readonly contentService: ContentServiceV1;
  readonly renderModeService: RenderModeServiceV1;
}

export const FeatureAppContext = React.createContext<FeatureAppContextState>(
  {} as FeatureAppContextState,
);

export interface FeatureAppContextProviderProps {
  readonly logger: Logger;
  readonly trackingService: TrackingServiceV2 | undefined;
  readonly contentService: ContentServiceV1;
  readonly renderModeService: RenderModeServiceV1;
}

export const FeatureAppContextProvider = ({
  logger,
  children,
  trackingService,
  contentService,
  renderModeService,
}: React.PropsWithChildren<FeatureAppContextProviderProps>): JSX.Element => {
  const contextValue = useMemo(() => {
    return {
      logger,
      trackingService,
      contentService,
      renderModeService,
    };
  }, []);
  return <FeatureAppContext.Provider value={contextValue}>{children}</FeatureAppContext.Provider>;
};

function getContext() {
  const context = React.useContext(FeatureAppContext);
  if (context === null) {
    throw Error('Did you forget to wrap your Feature App in <FeatureAppContextProvider>?');
  }
  return context;
}

export function useLogger(): Logger {
  return getContext().logger;
}
