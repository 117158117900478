import { useContext, useMemo } from 'react';
import { FeatureAppContext } from '../FeatureAppContext';

export interface TrackingManager {
  ready(version: string): void;
  click(linkType: string, label: string, targetURL: string): void;
  navigation(showList: boolean): void;
}

export interface TrackingEvent {
  event: {
    eventInfo: {
      eventAction: string;
      eventName: string;
    };
    attributes: Record<string, unknown>;
  };
}

function createTrackingPayload(
  action: string,
  name: string,
  attributes: Record<string, unknown>,
): TrackingEvent {
  const { host, pathname } = document.location;
  const defaultAttributes = {
    componentName: 'subpage-navigation',
    currentURL: `${host}${pathname}`,
  };
  return {
    event: {
      eventInfo: {
        eventAction: action,
        eventName: name,
      },
      attributes: {
        ...defaultAttributes,
        ...attributes,
      },
    },
  };
}

function createComponentPayload(version: string) {
  return {
    componentInfo: {
      implementer: 2,
      version,
    },
  };
}

export function useTrackingManager(): TrackingManager {
  const { trackingService, logger } = useContext(FeatureAppContext);

  return useMemo((): TrackingManager => {
    function track(...args: Parameters<typeof createTrackingPayload>): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);
        logger.debug('Sending tracking event', payload);
        trackingService.track(payload);
      }
    }

    function trackWithComponent(
      version: string,
      ...args: Parameters<typeof createTrackingPayload>
    ): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);
        if (logger) logger.debug('Sending tracking event', payload);
        const componentPayload = createComponentPayload(version);
        if (logger) logger.debug('Sending component info', componentPayload);
        trackingService.track({ ...payload, componentUpdate: componentPayload });
      }
    }
    return {
      ready(version) {
        trackWithComponent(version, 'feature_app_ready', 'subpage-navigation - feature app ready', {
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: '',
          pos: '',
        });
      },
      click(linkType, label, targetURL) {
        track('internal_link', `subpage navigation - click on ${linkType}`, {
          label,
          targetURL,
          clickID: '',
          elementName: linkType === 'cta' ? 'button' : 'text link',
          value: '',
          pos: '',
        });
      },

      navigation(showList) {
        const action = showList ? 'close' : 'open';
        track('navigation', `subpage navigation - ${action} navigation`, {
          label: '',
          targetURL: '',
          clickID: '',
          elementName: 'drop down',
          value: '',
          pos: '',
        });
      },
    };
  }, [trackingService]);
}
