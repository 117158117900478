import styled from 'styled-components';

export const Container = styled.div<{ withCTA: boolean }>`
  display: flex;
  flex-wrap: wrap;
  padding-block-start: var(
    ${(props) =>
      props.withCTA ? props.theme.responsive.spacing.s : props.theme.responsive.spacing.l}
  );
  padding-block-end: var(
    ${(props) =>
      props.withCTA ? props.theme.responsive.spacing.m : props.theme.responsive.spacing.xl}
  );
  padding-inline-start: var(${(props) => props.theme.responsive.spacing.m});
  padding-inline-end: var(${(props) => props.theme.responsive.spacing.m});
  --spacing-negative-s: calc(-1 * var(${(props) => props.theme.responsive.spacing.s}));
  --spacing-negative-l: calc(-1 * var(${(props) => props.theme.responsive.spacing.l}));
  --spacing-negative-xl: calc(-1 * var(${(props) => props.theme.responsive.spacing.xl}));
  margin-block-start: var(--spacing-negative-s);
  margin-inline-start: var(--spacing-negative-xl);

  @media (min-width: ${(props) => props.theme.breakpoints.s}px) {
    padding-inline-start: var(${(props) => props.theme.responsive.spacing.xl});
    padding-inline-end: var(${(props) => props.theme.responsive.spacing.xl});
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    padding-inline-start: 40px;
    padding-inline-end: 40px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    padding-block: var(${(props) => props.theme.responsive.spacing.l});
    margin-block-start: 0;
    margin-inline-start: 0;
    flex-wrap: nowrap;
    justify-content: flex-start;
    max-width: 100vw;
    box-sizing: border-box;
    padding-inline-start: 60px;
    padding-inline-end: 60px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    padding-inline-start: 96px;
    padding-inline-end: 96px;
  }
`;

export const LeftContainer = styled.div`
  flex: 100 1 auto;
  flex-direction: column;
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  margin-block-start: var(${(props) => props.theme.responsive.spacing.s});
  padding-inline-start: var(${(props) => props.theme.responsive.spacing.l});
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    flex: 1 1 auto;
    width: auto;
    flex-direction: row;
    justify-content: space-between;
    margin-block-start: 0;
    padding-inline-start: 0;
    overflow: hidden;
  }
`;

export const RightContainer = styled.div`
  flex-grow: 1;
  max-width: 100%;
  margin-block-start: var(${(props) => props.theme.responsive.spacing.s});
  margin-inline-start: var(${(props) => props.theme.responsive.spacing.l});
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin-inline-start: var(${(props) => props.theme.responsive.spacing.xl});
    margin-block-start: 0;
    margin-inline-start: 0;
    flex-grow: 0;
    display: inline-block;
  }
`;

export const MobileTempContainer = styled.div`
  position: relative;
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: none;
  }
`;

export const LinkList = styled.ul`
  --height: 0px;
  height: var(--height);
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-inline-start: var(--spacing-negative-xl);
  margin-block-start: var(${(props) => props.theme.responsive.spacing.s});
  position: absolute;
  top: 100%;
  background-color: white;
  z-index: 99;
  width: 100vw;
  box-sizing: border-box;
  visibility: hidden;
  transition: 400ms ${({ theme }) => theme.easing};
  transition-property: height, visibility;

  &.show {
    visibility: visible;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    margin-inline-start: -40px;
    padding-inline-start: 12px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: none;
  }
`;

export const LinkListItem = styled.li`
  margin-inline-start: var(${(props) => props.theme.responsive.spacing.xl});
  margin-inline-end: var(${(props) => props.theme.responsive.spacing.xl});
  padding-block-start: var(${(props) => props.theme.responsive.spacing.m});
  padding-block-end: var(${(props) => props.theme.responsive.spacing.m});

  &:first-child {
    margin-block-start: var(${(props) => props.theme.responsive.spacing.xs});
  }

  &:last-child {
    margin-block-end: var(${(props) => props.theme.responsive.spacing.m});
  }
`;

export const LinkListLink = styled.a`
  text-decoration: none;

  > span {
    color: ${({ theme }) => theme.colors.interaction.secondary.default}};
  }
`;

export const HeadlineContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    height: 58px;
    width: auto;
    display: flex;
    align-items: center;
    margin-inline-end: var(${(props) => props.theme.responsive.spacing.xl});
  }
`;

export const ToggleButton = styled.button`
  border: none;
  background-color: transparent;
  font-family: inherit;
  padding: 0;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    visibility: hidden;
    pointer-events: none;
  }
`;

export const DesktopNavigationBar = styled.div<{ withCTA: boolean }>`
  overflow: hidden;

  a:not([aria-current='true']) {
    color: ${({ theme }) => theme.colors.base.grey[60]};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.l - 1}px) {
    display: none;
  }
`;

export const MobileButton = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: none !important;
  }
`;
export const DesktopButton = styled.div`
  white-space: nowrap;
  margin-inline-start: calc(
    var(${(props) => props.theme.responsive.spacing.xl}) - var(
        ${(props) => props.theme.responsive.spacing.s}
      )
  );

  @media (max-width: ${(props) => props.theme.breakpoints.l - 1}px) {
    display: none !important;
  }
`;

export const StyledText = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    visibility: visible;
  }
`;

export const UnderlinedText = styled.span`
  border-bottom: 2px solid black;
  padding-bottom: 4px;
  display: inline-block;
  margin-block-end: -6px;
  > span {
    color: ${({ theme }) => theme.colors.interaction.secondary.active};
  }
`;
