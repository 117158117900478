// TODO FIX THIS
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unused-expressions */
import React, { useMemo, useReducer, useRef, useEffect, useContext } from 'react';
import { Button, NavigationBar, NavigationItem, Text } from '@audi/audi-ui-react';
import { UeElement, UeContainer, UeReference } from '@oneaudi/falcon-tools';
import { RenderMode } from '@oneaudi/render-mode-service';
import { LinkWithCurrentPage, SubPageNavigationProps } from '../../ContentType';
import { ArrowIcon, LeftArrowIcon } from '../ArrowIcon';
import {
  Container,
  DesktopNavigationBar,
  HeadlineContainer,
  LeftContainer,
  LinkList,
  LinkListItem,
  LinkListLink,
  MobileTempContainer,
  StyledText,
  ToggleButton,
  UnderlinedText,
} from './SubPageNavigationStyles';
import { CTA, handleTrackingAction } from './CallToAction';
import { DesktopNavigationItem } from './DesktopNavigationItem';
import { FeatureAppContext } from '../../FeatureAppContext';

const setHeight = (element: HTMLUListElement, showList: boolean) => () => {
  element.style.setProperty('--height', showList ? `${element.scrollHeight}px` : '0px');
};

const SubPageNavigation: React.FC<SubPageNavigationProps> = React.memo(
  ({ headline, href, links, cta, linkTracking, navigationTracking }: SubPageNavigationProps) => {
    const { renderModeService } = useContext(FeatureAppContext);
    const renderMode = renderModeService?.getRenderMode?.() || 0;

    const [showList, toggleShowList] = useReducer((state: boolean) => !state, false);
    const listRef = useRef<HTMLUListElement>(null);
    useEffect(() => {
      if (listRef !== null && listRef.current !== null) {
        window.requestAnimationFrame(setHeight(listRef.current, showList));
      }
    }, [showList]);
    const hasLinks = useMemo(() => links.length > 0, [links]);
    const hasCTA = useMemo(() => cta.length > 0, [cta]);

    const handleNavClick = () => {
      toggleShowList();
      if (navigationTracking) {
        navigationTracking(showList);
      }
    };

    const handleLinkClick = (
      event: React.MouseEvent<HTMLAnchorElement> | React.TouchEvent<HTMLAnchorElement>,
      url: string,
      opensInNewTab: boolean,
    ) => {
      event.preventDefault();
      if (opensInNewTab) {
        window.open(url, '_blank', 'noopener,noreferrer');
      } else {
        window.open(url, '_self');
      }
    };

    const renderHeadline = () => {
      if (hasLinks) {
        return (
          <HeadlineContainer>
            <ToggleButton onClick={() => handleNavClick()}>
              <StyledText>
                <Text variant="order4">
                  <UeElement type="text" property="headline" label="Main Page Title">
                    {headline}
                  </UeElement>
                </Text>
              </StyledText>
              <ArrowIcon rotated={showList} />
            </ToggleButton>
          </HeadlineContainer>
        );
      }
      if (href) {
        return (
          <HeadlineContainer>
            <Button
              variant="text"
              icon={<LeftArrowIcon />}
              href={href}
              onClick={() => handleTrackingAction('back', headline, href, linkTracking)}
            >
              <StyledText>
                <Text variant="order4">
                  <UeElement type="text" property="headline" label="Main Page Title">
                    {headline}
                  </UeElement>
                </Text>
              </StyledText>
            </Button>
          </HeadlineContainer>
        );
      }
      return (
        <HeadlineContainer>
          <StyledText>
            <Text variant="order4">{headline}</Text>
          </StyledText>
        </HeadlineContainer>
      );
    };

    const setSelected = (links: LinkWithCurrentPage[]) => {
      const selected = links
        .filter((item) => item.currentPage)
        .map((item) => item.text)
        .toString();
      return `nav-item-${selected}__scrolling`;
    };

    const renderLinks = () => {
      if (links.length === 0 && renderMode === RenderMode.DEFAULT) {
        return null;
      }
      return (
        <>
          <DesktopNavigationBar withCTA={hasCTA}>
            <UeContainer propertyPath="links" label="Sub Pages">
              <NavigationBar id="nav-bar__scrolling" selected={setSelected(links)}>
                {links.map(({ text, href, opensInNewTab, currentPage }, i) => {
                  const itemId = `nav-item-${text}__scrolling`;

                  // conditional needed since NavigationItem must be direct children of NavigationBar
                  if (renderMode !== RenderMode.DEFAULT) {
                    if (currentPage) {
                      return (
                        <UeReference key={text} propertyPath={`links.${i}`} behavior="component">
                          <NavigationItem key={text} id={itemId}>
                            <UeElement type="text" label="Sub Pages">
                              {text}
                            </UeElement>
                          </NavigationItem>
                        </UeReference>
                      );
                    }
                    return (
                      <UeReference key={text} propertyPath={`links.${i}`} behavior="component">
                        <DesktopNavigationItem
                          key={text}
                          id={itemId}
                          href={opensInNewTab ? '#' : href}
                          onClick={(e) => {
                            if (opensInNewTab) {
                              handleLinkClick(e, href, opensInNewTab);
                            }
                          }}
                        >
                          <UeElement type="text" label="Sub Pages">
                            {text}
                          </UeElement>
                        </DesktopNavigationItem>
                      </UeReference>
                    );
                  }
                  if (currentPage) {
                    return (
                      <NavigationItem key={text} id={itemId}>
                        {text}
                      </NavigationItem>
                    );
                  }
                  return (
                    <DesktopNavigationItem
                      key={text}
                      id={itemId}
                      href={opensInNewTab ? '#' : href}
                      onClick={(e) => {
                        handleTrackingAction('link', text, href, linkTracking);
                        if (opensInNewTab) {
                          handleLinkClick(e, href, opensInNewTab);
                        }
                      }}
                    >
                      {text}
                    </DesktopNavigationItem>
                  );
                })}
              </NavigationBar>
            </UeContainer>
          </DesktopNavigationBar>
          <MobileTempContainer>
            <LinkList ref={listRef} className={showList ? 'show' : undefined}>
              {links.map(({ text, href, opensInNewTab, currentPage }) => {
                if (currentPage) {
                  return (
                    <LinkListItem key={text}>
                      <UnderlinedText>
                        <Text as="span">
                          <UeElement type="text">{text}</UeElement>
                        </Text>
                      </UnderlinedText>
                    </LinkListItem>
                  );
                }
                return (
                  <LinkListItem
                    key={text}
                    onClick={() => handleTrackingAction('link', text, href, linkTracking)}
                  >
                    <LinkListLink href={href} target={opensInNewTab ? '_blank' : '_self'}>
                      <Text as="span">
                        <UeElement type="text">{text}</UeElement>
                      </Text>
                    </LinkListLink>
                  </LinkListItem>
                );
              })}
            </LinkList>
          </MobileTempContainer>
        </>
      );
    };

    return (
      <Container data-testid="subpage-navigation-container" withCTA={hasCTA}>
        <LeftContainer>
          {renderHeadline()}
          {renderLinks()}
        </LeftContainer>
        {hasCTA && <CTA {...cta[0]} />}
      </Container>
    );
  },
);

export default SubPageNavigation;
