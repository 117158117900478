/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { ThemeProvider } from '@audi/audi-ui-react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import { ContentContextProvider } from '@oneaudi/feature-app-utils';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import AsyncFeatureApp from './AsyncFeatureApp';
import { FeatureAppContextProvider } from './FeatureAppContext';
import { Content } from './ContentType';
import { mapHeadlessContent } from './utils/mapHeadlessContent';

export interface Dependencies extends FeatureServices {
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 's2:logger'?: Logger;
  readonly 'audi-content-service': ContentServiceV1;
  readonly 's2:serialized-state-manager'?: SerializedStateManagerV1;
  readonly 'audi-tracking-service'?: TrackingServiceV2;
  readonly 'audi-render-mode-service': RenderModeServiceV1;
}

export interface InitialState {
  readonly content: Content;
}

export type AsyncStateHolder = (() => Promise<InitialState>) | InitialState | undefined;

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, Dependencies> = {
  dependencies: {
    featureServices: {
      'audi-content-service': '^1.0.0',
    },
    externals: {
      '@audi/audi-ui-react-v2': '^3.4.0',
      '@feature-hub/react': '^3.4.0',
      react: '^17.0.2 || ^18.2.0',
      'react-dom': '^17.0.2 || ^18.2.0',
      'styled-components': '^5.3.0',
    },
  },

  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  create: ({ featureServices }: FeatureAppEnvironment<Dependencies, void>) => {
    const {
      'audi-tracking-service': trackingService,
      's2:logger': s2logger,
      'audi-content-service': contentService,
      's2:async-ssr-manager': asyncSsrManager,
      's2:serialized-state-manager': serializedStateManager,
      'audi-render-mode-service': renderModeService,
    } = featureServices;

    if (trackingService) {
      trackingService.featureAppName = 'oneaudi-feature-app-subpage-navigation';
    }

    const logger = s2logger || console;
    logger.info('Feature App created.');

    const initialContent = contentService.getContent();
    logger.info('Feature App created, initialContent', initialContent);

    const content = mapHeadlessContent(initialContent, renderModeService?.getRenderMode?.() || 0);
    logger.info('Feature App created, content', content);

    let asyncStateHolder: AsyncStateHolder;
    if (asyncSsrManager) {
      // on the server
      asyncSsrManager.scheduleRerender(
        (async () => {
          asyncStateHolder = { content };
          serializedStateManager?.register(() => JSON.stringify(asyncStateHolder));
        })(),
      );
    } else {
      // on the client
      const serializedState = serializedStateManager?.getSerializedState();
      if (serializedState) {
        asyncStateHolder = JSON.parse(serializedState) as InitialState;
      } else {
        logger?.warn(
          `Serialized state not found!. Possible reasons: \n 1. Running app in dev mode using the "audi-static-demo-integrator" which does not support SSR \n 2. Running app in prod mode and SSR is broken`,
        );
        asyncStateHolder = { content };
      }
    }

    return {
      render: () => {
        return (
          <ThemeProvider>
            <ContentContextProvider contentService={contentService}>
              <FeatureAppContextProvider
                logger={logger}
                trackingService={trackingService}
                contentService={contentService}
                renderModeService={renderModeService}
              >
                <AsyncFeatureApp asyncStateHolder={asyncStateHolder} />
              </FeatureAppContextProvider>
            </ContentContextProvider>
          </ThemeProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;
