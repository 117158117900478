import React, { useContext } from 'react';
import { useContent } from '@oneaudi/feature-app-utils';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import pkg from '../../package.json';
import SubPageNavigation from './components/subpage-navigation/SubPageNavigation';
import { InitialState } from './FeatureHubAppDefinition';
import { FeatureAppContext, useLogger } from './FeatureAppContext';
import { AsyncFeatureAppProps, Content, HeadlessContent } from './ContentType';
import { useTrackingManager } from './utils/useTrackingManager';
import { mapHeadlessContent } from './utils/mapHeadlessContent';

const SubpageNavigationFeatureApp: React.FC<InitialState> = (initalState: InitialState) => {
  const { content } = initalState;
  const trackingManager = useTrackingManager();

  React.useEffect(() => {
    trackingManager.ready(pkg.version);
  });

  const linkTracking = React.useCallback((linkType: string, label: string, targetURL: string) => {
    trackingManager.click(linkType, label, targetURL);
  }, []);

  const navigationTracking = React.useCallback((isOpen: boolean) => {
    trackingManager.navigation(isOpen);
  }, []);

  if (!content) {
    return null;
  }

  return (
    <SubPageNavigation
      {...content}
      linkTracking={linkTracking}
      navigationTracking={navigationTracking}
    />
  );
};

const AsyncFeatureApp: React.FC<AsyncFeatureAppProps> = ({
  asyncStateHolder,
}: AsyncFeatureAppProps) => {
  const logger = useLogger();
  const { contentService, renderModeService } = useContext(FeatureAppContext);

  const [state, setState] = React.useState<InitialState | undefined>(
    typeof asyncStateHolder === 'object' ? asyncStateHolder : undefined,
  );

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const initialContent = useContent<Content | HeadlessContent | any>();
  const renderMode = renderModeService?.getRenderMode?.() ?? 0;
  const content = mapHeadlessContent(initialContent, renderMode);

  // update content changed with FACE in development environment
  React.useEffect(() => {
    let mounted = true;
    if (state && content && mounted) {
      if (mounted) setState({ content });
    }

    return () => {
      mounted = false;
    };
  }, [initialContent]);

  React.useEffect(() => {
    let mounted = true;

    // when asyncStateHolder is a function it means the state could not be properly
    // serialized by the server and it is not available on the client. In that case
    // this effect will try to fetch the state as soon as the component is mounted on the client.
    if (typeof asyncStateHolder === 'function') {
      logger?.info('SSR did not serialize any state');
      asyncStateHolder().then((newState) => {
        if (mounted) {
          setState(newState);
        }
      });
    } else {
      logger?.info('SSR serialized state: ', asyncStateHolder);
    }

    return () => {
      mounted = false;
    };
  }, []);

  if (!state) {
    return null;
  }

  return renderModeService && renderMode === 1 ? (
    <UniversalEditorProvider contentService={contentService} renderModeService={renderModeService}>
      <SubpageNavigationFeatureApp {...state} />
    </UniversalEditorProvider>
  ) : (
    <SubpageNavigationFeatureApp {...state} />
  );
};

export default AsyncFeatureApp;
