import React from 'react';
import styled from 'styled-components';

const BaseIcon = styled.svg`
  grid-column: arrow-icon;
  color: ${(props) => props.theme.colors.ui.primary};
  transform: rotateZ(0deg);
  pointer-events: none;
  flex-shrink: 0;

  &.rotated-90 {
    transform: rotateZ(90deg);
  }
`;

const StyledIcon = styled(BaseIcon)`
  transition: transform 200ms ${(props) => props.theme.easing};
  margin-inline-start: var(${(props) => props.theme.responsive.spacing.s});

  &.rotated-180 {
    transform: rotateZ(-180deg);
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: none;
  }
`;

const StyledLeftIcon = styled(BaseIcon)`
  transform: rotateZ(90deg);
`;

export interface ArrowIconProps {
  readonly rotated: boolean;
}

export const ArrowIcon: React.FC<ArrowIconProps> = ({ rotated }) => (
  <StyledIcon
    className={rotated ? `rotated-180` : ''}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 10L12.5 15.5 18 10"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </StyledIcon>
);

export const LeftArrowIcon: React.FC = () => (
  <StyledLeftIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    display="flex"
    align-items="center"
  >
    <path
      d="M7 10L12.5 15.5 18 10"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />
  </StyledLeftIcon>
);
