import { RenderMode } from '@oneaudi/render-mode-service';
import { Content, HeadlessContent, LinkWithCurrentPage } from '../ContentType';

export const mapLinks = (content: HeadlessContent, renderMode: RenderMode) => {
  const links: LinkWithCurrentPage[] = [];
  if (content.fields.links?.length > 0) {
    content.fields.links.forEach((link) => {
      const href = providePlaceHolderText(link.fields.href, 'href Placeholder', renderMode);
      const text = providePlaceHolderText(link.fields.text, 'text Placeholder', renderMode);

      if (href.length > 0 && text.length > 0) {
        links.push({
          href,
          text,
          opensInNewTab: link.fields.opensInNewTab === true,
          currentPage: link.fields.currentPage === true,
        });
      }
    });
  }
  return links;
};

export const mapHeadlessContent = (
  content: Content | HeadlessContent,
  renderMode: RenderMode,
): Content => {
  if ('__type' in content && content.__type === 'aem-headless') {
    const normalisedContent: Content = {
      headline: providePlaceHolderText(content.fields.headline, 'Headline Placeholder', renderMode),
      href: content.fields.href,
      links: mapLinks(content, renderMode),
      cta: [],
    };
    if (
      content.fields.cta_0_text &&
      content.fields.cta_0_text.length > 0 &&
      content.fields.cta_0_href &&
      content.fields.cta_0_href.length > 0
    ) {
      normalisedContent.cta.push({
        text: content.fields.cta_0_text,
        href: content.fields.cta_0_href,
        opensInNewTab: content.fields.cta_0_opensInNewTab,
      });
    }
    return normalisedContent;
  }
  return content as Content;
};

/**
 * If a field is has no content but we are in a the RenderMode.EDIT
 * Then we set a placeholder text to make the editing in the UE possible.
 * Otherwise the element may not be shown, which makes it impossible to edit it in the
 * UE
 *
 * @param field
 * @param placeholder
 * @param renderMode
 * @returns
 */
function providePlaceHolderText(field: string, placeholder: string, renderMode: RenderMode) {
  if (!field && renderMode === RenderMode.EDIT) {
    return placeholder;
  }

  return field;
}
